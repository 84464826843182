import {isDefined, set} from "@vueuse/core";
import {fullHash} from "~/composable/fullHash";

export const useSitesStore = defineStore('sitesStore', () => {
	const siteId = ref(null);
	const zones = ref([]);
	const publishingCategories = ref([]);
	const authStore = useAuthStore();
	const progressStore = useProgressIndicatorStore();

	const getFirstZoneFirstPlacement = computed(() => toValue(zones)?.at(0)?.placements.at(0));
	async function getSites() {
		progressStore.updateStatus(true);
		const data = await $fetch('/api/sites/get-sites', {
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
			}
		});
		progressStore.updateStatus(false);
	}

	async function updateListSites() {
		const trafficNetworkStore = useTrafficNetworksStore();
		const data = await $fetch('/api/sites/zones', {
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
			}
		});
		set(zones, enrichZonesWithTrafficNetworks(toValue(data.zones), trafficNetworkStore.trafficNetworks));
		set(siteId, toValue(data.siteId));
	}

	function enrichZonesWithTrafficNetworks(zones, networks) {
		return zones.map(zone => {
			const zoneId = zone.id;
			// Знайти відповідні мережі, які мають збіги в `epom_zone`
			const trafficNetworksZones = networks.reduce((acc, network) => {

				const matchingZone = network.zones.find(z => z.epom_zone === String(zoneId));


				if (matchingZone) {
					acc[network.id] = matchingZone;
				}
				return acc;
			}, {});

			// Додати `traffic_networks_zones` до кожного `zone`
			zone.traffic_networks_zones = trafficNetworksZones;

			return zone;
		});
	}


	async function deleteSite(siteId: string) {
		progressStore.updateStatus(true);
		await $fetch('/api/sites/delete-site',{
			method: 'POST',
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
				siteId,
			}
		});
		await updateListSites();
		progressStore.updateStatus(false);
	}

	async function worksSite({
		id = undefined,
		name= '',
		active = false,
	}) {
		progressStore.updateStatus(true);
		await $fetch('/api/sites/update-site',{
			method: 'POST',
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
				siteId: toValue(siteId),
				...(isDefined(id) && { id }),
				name,
				active,
			}
		});
		await updateListSites();
		progressStore.updateStatus(false);
	}

	return {
		zones,
		siteId,
		publishingCategories,
		getFirstZoneFirstPlacement,
		updateListSites,
		deleteSite,
		worksSite,
	}
})
